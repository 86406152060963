.phase-container{
    border:1px solid black;
    padding:10px;
    box-sizing:border-box;

}
.phase-completion-container{
    display:flex;
}
.bar-container{
    width:100%;
    margin-left:5px;
    margin-right:5px;
    box-sizing:border-box;
}
.name{
    font-weight:bold;
    font-size:30px;
    margin-bottom:0;
}
.org{
    font-weight:bold;
    font-size:20px;
    color:rgb(41, 0, 155);
    margin-top:0;
}
.phase-progress{
    font-weight:bold;
}


.checklist-item{
    /* min-height:100px; */
    box-sizing:border-box;
    padding:10px;
    margin-bottom:10px; 
    position:relative;
    overflow:hidden;
    border-radius:0 10px 10px 0;
}
.ci-attachment{
    width:50px;
    position:absolute;
    top:0;
    left:0;
    height:100%;
    z-index:100;
    background-color: rgb(0, 176, 176);
    display:flex;
    justify-content:center;
    align-items:center;
    color:white;
    text-decoration: none;
}
.ci-inner:hover{
    cursor:pointer;
}
.ci-inner{
    padding-right: 50px;
    padding-left: 50px;
}
.ci-title{
    font-weight:bold;
    font-size:20px;
}
.ci-check{
    width:50px;
    position:absolute;
    top:0;
    right:0;
    height:100%;
    z-index:100;
    background-color: rgb(200, 200, 200);
    display:flex;
    justify-content:center;
    align-items:center;
}
.ci-instructions{
    background-color: white;
    box-sizing: border-box;
    padding:10px;
    margin-top:10px;
    color:black;
    box-shadow:inset 0 0 5px 1px grey;
    text-align:justify;
    text-justify: inter-word;
}
.ci-instructions:hover{
    cursor:default
}
.checkbox-container{
    display:flex;
    justify-content:center;
    margin-top:10px;
    font-weight:bold;
}