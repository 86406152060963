.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.header-title{
  font-weight:bold;
  position:absolute;
  right:200px;
  top:150px;
  font-size:40px;
  color:blue;
  text-shadow:1px 1px 1px #282c34;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .menu{
  border:3px blue solid;
  background-color:rgba(71, 209, 255, 0.132);
  width:100%;
  padding:10px;
  box-sizing:border-box;
  min-height:300px;
  border-radius:10px;
  margin:10px;
} */
/* .menu{
  transition: width .5s;
} */
.box{
  width:200px;
  height:200px;
  border:1px teal solid;
  box-shadow: 1px 1px 10px grey;
  margin:10px;
  box-sizing:border-box;
  transition: width .5s, transform .8s;
  overflow:hidden;
  position:relative;
  background-size:100%;
  /* background:center; */
}
.box:hover{
  cursor:pointer;
  border:2px teal solid;  
  box-sizing:border-box;
  /* transform: rotateY(180deg); */
}
.box.active{
  width:400px;
}
.box:active{
  box-shadow: 1px 1px 2px grey;
}
.box-container{
  display:flex;
  flex-flow: row wrap;
  justify-content:space-evenly;
}
.box-spacer{
  /* height:150px; */
}
.box-description{
  padding:5px;
  box-sizing: border-box;
}
.box-title{
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
}
.btn{
  margin-top:5px;
  margin-bottom:5px;
}
.body-container{
  min-height:calc(100vh - 400px)
}
.resource-button:hover{
  cursor:pointer;
  box-shadow:0px 0px 0px grey;
}

.nav-link.active {
  /* color: rgb(4, 0, 62) !important; */
  /* background-color: #00ccff;     */
  font-weight:600
}

@media (max-width: 1600px) {
  .navbar-collapse a
    {
        background-color:white;
    }
}
.nav-link.login-button, .nav-link.login-button.active{
  background:#00ccff;
  border-radius:5px;
  min-width:100px;
  color:white;
  font-weight:bold;

}

.guide-container p{
  margin-top:40px;
  text-align:left;
  font-size:18px;
}
.steps-container p{
  margin-top:40px;
  text-align:left;
  font-size:18px;
}

.boxlist{
  width:100%;
  /* height:100px; */
  border:1px teal solid;
  box-shadow: 1px 1px 10px grey;
  margin:10px;
  box-sizing:border-box;
  /* transition: height .5s, transform .8s; */
  overflow:hidden;
  position:relative;
  /* background-size:100%; */
  /* background:center; */
}
.boxlist:hover{
  cursor:pointer;
  border:2px teal solid;  
  box-sizing:border-box;
  /* transform: rotateY(180deg); */
}
/* .boxlist.active{
  height:auto;
} */
.boxlist:active{
  box-shadow: 1px 1px 2px grey;
}
.boxlist-descr-container{
  /* height:150px; */
}
.boxlist-description{
  padding:5px;
  box-sizing: border-box;
  text-align: left;
}
.boxlist-title{
  width:100%;
  /* position:absolute; */
  /* bottom:0;
  left:0; */
}