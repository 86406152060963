.onboarding-box:hover .border{
    display:block;
}
.onboarding-box .bar-container:hover {
    cursor:pointer;
}
.onboarding-box .bar-container:active .bar{
    box-shadow: inset 2px 2px 8px black !important;
}
.border{
    display:none;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:#00ffff;
    z-index:3;
    top:0;
    left:0;
}
.new {
    position: absolute;
    display: block;
    top: -50%;
    left: -50%;
    z-index: 2;
    display: block;
    height: 200%;
    width: 200%;
    transform: rotate(-45deg);
    overflow: hidden;
    /* background: linear-gradient(to right, #fff 20%, #fff 40%, #ECD08C 50%, #ECD08C 55%, #fff 70%, #fff 100%); */
    background: linear-gradient(to right, #fff 20%, #fff 40%, #f47a00 50%, #cd7f26 55%, #fff 70%, #fff 100%);
    background-size: 200% auto;
    
    animation: shine 2s linear infinite;
  }
  
  /*Begin shimmer code*/
  
  @keyframes shine {
      to {
        background-position: 130% center;
      }
    }